export const Pahts = {
   indormation: 'indormation_about_organization',
   structure: 'structure',
   doc: 'documents',
   admin: 'admin',
   postingspost: 'new_posting_post',
   note: 'note',
   hrental: 'hrental',
   news: 'fullnews',
   onepostpage: 'onepostpage'
}